import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import React from 'react'

import ShareLinks from '../ShareLinks'
import Wrapper from '../UI/Wrapper'
import { HeroSection } from './styles'

interface ResourceHeroProps {
  headline: string
  topicTag?: string
  author?: string
  pubDate?: string
  resourceType?: string
  featureImage: any
  locale?: string
}

const ResourceHero = (props: ResourceHeroProps) => {
  const {
    headline,
    topicTag,
    author = '',
    resourceType = '',
    featureImage,
  } = props
  const isPressRelease = resourceType === 'Press Release'

  const image = getImage(featureImage?.imgixImage)

  return (
    <HeroSection>
      <Wrapper>
        <Typography className="headline" component="h1" variant="h2">
          {headline}
        </Typography>
        {topicTag ? (
          <Typography className="tag" component="p" variant="caption">
            {topicTag}
          </Typography>
        ) : null}
        {image ? (
          <GatsbyImage
            alt={featureImage?.description || ''}
            className="heroImage"
            image={image}
          />
        ) : null}
        {!image && isPressRelease ? (
          <StaticImage
            alt="Press Release"
            className="heroImage"
            src="../../images/richardson-news-events.jpg"
          />
        ) : null}
        <Grid
          className="metaContainer"
          container
          id="resourceMetaContainer"
          justifyContent="space-between"
        >
          <div className="metaInfo">
            <Typography className="tag" component="p" variant="caption">
              {author ? <span>{author}</span> : null}
            </Typography>
          </div>
          <ShareLinks />
        </Grid>
      </Wrapper>
    </HeroSection>
  )
}

export default ResourceHero
