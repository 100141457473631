import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import ClearIcon from '@material-ui/icons/Clear'
import { FormikProps } from 'formik'
import parse from 'html-react-parser'
import React, { FC, useEffect, useRef, useState } from 'react'

import { useClearbitUserData } from '../../hooks/useClearbitUserData'
import { useDebouncedValue } from '../../hooks/useDebouncedValue'
import {
  FORM_LOCALSTORAGE_KEY,
  getBusinessEmailFromLocalStorage,
  LocalStorageFormDataType,
  useAskGDPRPermission,
  useForm,
} from '../../utils/form/useForm'
import validationSchemaEmailOnlyForm from '../../utils/form/validateEmailOnlyForm'
import { getRegionFromCountryCode } from '../../utils/getRegionFromCountryCode'
import {
  cleanString,
  jsonParseLocalStorage,
  useServerSideSafeValue,
} from '../../utils/helpers'
import { useHandleSubmitWithRecaptcha } from '../../utils/recaptcha'
import GoogleAnalyticsHiddenFields from '../GoogleAnalyticsHiddenFields'
import Button from '../UI/Button/Button'
import { FormLoader, FormWrapper } from './styles'

interface DownloadResourceFormProps {
  resourceUrl: string
  resourceDownloadUrl: string
  formContent: any
  manualDownloadPromptText?: string
}

type InitialFormValueTypes = {
  businessEmail: string
  firstName: string
  lastName: string
  phoneNumber?: string
  companyName?: string
  jobTitle?: string
  country: string
  GDPRConsent?: boolean
  website?: string
}

type InputProps = {
  isLoading: boolean
  formContent: any
  // eslint-disable-next-line react/no-unused-prop-types
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  form: FormikProps<InitialFormValueTypes>
}

const BusinessEmailInput = React.forwardRef(
  ({ isLoading, formContent, form, onBlur, onFocus }: InputProps, ref) => (
    <TextField
      className="textField textField"
      disabled={isLoading}
      error={Boolean(
        form.touched.businessEmail ? form.errors.businessEmail : null,
      )}
      helperText={form.touched.businessEmail ? form.errors.businessEmail : null}
      id="businessEmail"
      inputRef={ref}
      label={cleanString(formContent.business_email_label?.[0]?.copy)}
      name="businessEmail"
      onBlur={onBlur}
      onChange={form.handleChange}
      onFocus={onFocus}
      value={form.values.businessEmail}
      variant="filled"
    />
  ),
)

const Row = ({ children }: { children: React.ReactNode }) => (
  <Grid alignItems="flex-start" container justifyContent="space-between">
    {children}
  </Grid>
)

const DownloadResourceForm: FC<DownloadResourceFormProps> = ({
  resourceUrl,
  resourceDownloadUrl,
  formContent,
  manualDownloadPromptText,
}) => {
  const [isEmailFocused, setIsEmailFocused] = useState(false)
  const [formWasPrefilled, setFormWasPrefilled] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [manualDownloadUrl, setManualDownloadUrl] = useState('')
  const [didShowGDPRConsent, setDidShowGDPRConsent] = useState(false)
  const [userDataAvailableInLocalStorage, setUserDataAvailableInLocalStorage] =
    useState(false)

  const businessEmailAddressRef = useRef<HTMLInputElement>(null)

  const downloadText = manualDownloadPromptText || ''
  const downloadFromURL =
    typeof window !== 'undefined' ? window?.location?.href : ''

  const nameArray = resourceUrl?.split('/') || []
  const filename = nameArray?.length
    ? nameArray[nameArray.length - 1]
    : 'download.pdf'

  const initialFormValues: InitialFormValueTypes = {
    firstName: '',
    lastName: '',
    businessEmail: '',
    country: '',
    GDPRConsent: false,
    jobTitle: '',
  }

  const formId = 'downloadFormEmailOnly'

  const isLinkedIn = () => {
    if (typeof window !== 'undefined') {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera

      if (/LinkedIn/i.test(userAgent)) {
        return true
      }

      return false
    }

    return false
  }

  const form = useForm({
    initialValues: initialFormValues,
    formId,
    endpoint: 'gated-content',
    errors: formContent,
    validationSchema: validationSchemaEmailOnlyForm(
      initialFormValues,
      formContent,
    ),
    onSuccess: (values) => {
      if (manualDownloadUrl) {
        if (isLinkedIn()) {
          document.location = resourceDownloadUrl || resourceUrl
        } else if (shouldOpenInNewTab(resourceDownloadUrl)) {
          window.open(resourceDownloadUrl, '_blank', 'noopener noreferrer')
        } else {
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = manualDownloadUrl
          a.target = '_blank'
          a.rel = 'noopener noreferrer'
          // specify document filename
          a.download = filename
          document.body.appendChild(a)
          a.click()
        }
        setIsSubmitted(true)
      }
    },
    emailField: 'businessEmail',
  })

  const handleSubmit = useHandleSubmitWithRecaptcha(
    form.handleSubmit,
    form.isValid,
  )

  const shouldAskGDPRPermission = useAskGDPRPermission(
    formContent,
    form?.values?.country || '',
    form?.values?.GDPRConsent || false,
  )

  const { value: localStorageFormData } = useServerSideSafeValue(() =>
    jsonParseLocalStorage<Omit<LocalStorageFormDataType, 'trainingInterests'>>(
      localStorage?.getItem(FORM_LOCALSTORAGE_KEY),
    ),
  )

  const { value: localStorageBusinessEmail, isReady } = useServerSideSafeValue(
    () => getBusinessEmailFromLocalStorage(),
  )

  const [
    debouncedEmail,
    forceSetDebouncedEmail,
    { isPending: hasPendingChanges },
  ] = useDebouncedValue(form?.values?.businessEmail, 500)

  const { rawData: clearbitFormData, isLoading } = useClearbitUserData(
    debouncedEmail || '',
  )

  const countryRegion = getRegionFromCountryCode(
    clearbitFormData?.company?.geo?.countryCode || '',
  )

  const localData = localStorageFormData?.[debouncedEmail ? debouncedEmail : '']

  const isBrochureDownloadForm = false

  const shouldShowExtraFieldsForWhichClearbitDataWasntFound = (() => {
    if (userDataAvailableInLocalStorage) {
      return !localData?.companyName || !localData?.jobTitle
    }

    if (formWasPrefilled) {
      return (
        !clearbitFormData?.company?.name ||
        !clearbitFormData?.person?.employment?.title
      )
    }

    return false
  })()

  const prefillForm = () => {
    // prefills the form with either local storage data or clearbit user data
    const prefilledFormValues = {
      businessEmail:
        localData?.businessEmail ||
        clearbitFormData?.person?.email ||
        form?.values?.businessEmail ||
        '',
      firstName:
        localData?.firstName || clearbitFormData?.person?.name?.givenName || '',
      lastName:
        localData?.lastName || clearbitFormData?.person?.name?.familyName || '',
      country: localData?.country || countryRegion || 'undefined',
      companyName:
        localData?.companyName || clearbitFormData?.company?.name || '',
      jobTitle:
        localData?.jobTitle ||
        clearbitFormData?.person?.employment?.title ||
        '',
      phoneNumber:
        localData?.phoneNumber || clearbitFormData?.company?.phone || '',
      GDPRConsent: localData?.GDPRConsent || Boolean(''),
      numberOfSellers: '',
      trainingInterests: '',
    }

    forceSetDebouncedEmail(prefilledFormValues.businessEmail || '')
    form.setValues({ ...form.values, ...prefilledFormValues })
    setFormWasPrefilled(true)
  }

  // prefills the business email address field on page load with local storage email
  useEffect(() => {
    if (isReady && localStorageFormData) {
      forceSetDebouncedEmail(localStorageBusinessEmail || '')
      setUserDataAvailableInLocalStorage(true)
      form.setValues({ businessEmail: localStorageBusinessEmail || '' })
    }
  }, [isReady])

  // re-prefills the form according to entered business email address
  useEffect(() => {
    if (debouncedEmail) {
      prefillForm()
    }
  }, [clearbitFormData])

  const clearBusinessEmail = () => {
    form.setValues(initialFormValues)
    forceSetDebouncedEmail('')
    setUserDataAvailableInLocalStorage(false)
    setTimeout(() => businessEmailAddressRef?.current?.focus(), 100)
  }

  const shouldOpenInNewTab = (url: string | null | undefined): boolean => {
    const videoDomains = ['youtube.com', 'youtu.be', 'vimeo.com']
    const videoExtensions = [
      '.mp4',
      '.mov',
      '.avi',
      '.mkv',
      '.wmv',
      '.flv',
      '.webm',
    ]

    if (!url) {
      return false
    }

    const isVideoDomain = videoDomains.some((domain) => url?.includes(domain))
    if (isVideoDomain) {
      return true
    }

    const isVideoExtension = videoExtensions.some((extension) =>
      url?.endsWith(extension),
    )
    if (isVideoExtension) {
      return true
    }

    const isPdf = url?.endsWith('.pdf')
    if (isPdf) {
      return false
    }

    return true
  }

  useEffect(() => {
    const handleResourceDownload = async () => {
      try {
        if (shouldOpenInNewTab(resourceDownloadUrl)) {
          setManualDownloadUrl(resourceDownloadUrl)
        } else if (resourceUrl) {
          const response = await fetch(resourceUrl)
          const blob = await response.blob()
          const blobUrl = window.URL.createObjectURL(blob)
          setManualDownloadUrl(blobUrl)
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error downloading document', error)
      }
    }
    handleResourceDownload()

    return () => window.URL.revokeObjectURL(manualDownloadUrl)
  }, [])

  const prefilledBusinessEmailInput = (
    <>
      <Typography className="formSubhead" component="h3" variant="h3">
        Hi, welcome back {form?.values?.firstName}!
      </Typography>
      <Grid container>
        <TextField
          InputProps={{
            endAdornment: (
              <ClearIcon
                className="clearEmailIcon"
                fontSize="medium"
                id="clearEmailIcon"
                onClick={clearBusinessEmail}
              />
            ),
            readOnly: Boolean(localStorageFormData),
          }}
          className={`textField ${
            localStorageFormData ? 'customBackgroundInput' : ''
          } ${
            userDataAvailableInLocalStorage &&
            shouldShowExtraFieldsForWhichClearbitDataWasntFound &&
            isBrochureDownloadForm
              ? 'hasFieldsBelow'
              : ''
          }`}
          disabled={isLoading}
          error={Boolean(
            form.touched.businessEmail ? form.errors.businessEmail : null,
          )}
          helperText={
            form.touched.businessEmail ? form.errors.businessEmail : null
          }
          id="businessEmail"
          label={cleanString(formContent.business_email_label?.[0]?.copy)}
          name="businessEmail"
          onChange={form.handleChange}
          value={form.values.businessEmail}
          variant="filled"
        />
      </Grid>
    </>
  )

  return (
    <FormWrapper id="formWrapper">
      {isSubmitted ? (
        <>
          <Typography
            className="formSubhead"
            component="p"
            style={{ maxWidth: 'unset' }}
            variant="h3"
          >
            {formContent.success_message}
          </Typography>
          {manualDownloadUrl ? (
            <Typography
              className="formSubhead"
              component="p"
              style={{ maxWidth: 'unset' }}
              variant="body1"
            >
              <a
                download={filename}
                href={manualDownloadUrl}
                rel="noopener noreferrer"
                style={{ color: '#fff' }}
                target="_blank"
              >
                {downloadText}
              </a>
            </Typography>
          ) : null}
        </>
      ) : // only show the form if the resource URL is present; it's not a required field in the CMS
      resourceUrl !== '/' && typeof resourceUrl !== 'undefined' ? (
        <form className="downloadForm" id={formId} onSubmit={handleSubmit}>
          <GoogleAnalyticsHiddenFields />
          <input name="website" type="hidden" value={downloadFromURL} />

          {userDataAvailableInLocalStorage ? (
            <>{prefilledBusinessEmailInput}</>
          ) : (
            <>
              <Typography className="formSubhead" component="h3" variant="h3">
                {formContent.headline}
              </Typography>

              <Row>
                <BusinessEmailInput
                  form={form}
                  formContent={formContent}
                  isLoading={Boolean(isLoading && !isEmailFocused)}
                  onBlur={(event) => {
                    forceSetDebouncedEmail(event.target.value)
                    setIsEmailFocused(false)
                  }}
                  onFocus={() => {
                    setIsEmailFocused(true)
                  }}
                  ref={businessEmailAddressRef}
                />
              </Row>
            </>
          )}

          {isLoading && !isEmailFocused ? <FormLoader /> : null}

          {didShowGDPRConsent || shouldAskGDPRPermission ? (
            <FormGroup className="gdpr-consent">
              <FormControlLabel
                control={
                  <Checkbox
                    id="GDPRConsent"
                    name="GDPRConsent"
                    onChange={form.handleChange}
                    onClick={() => setDidShowGDPRConsent(true)}
                    value={form.values.GDPRConsent}
                  />
                }
                label={formContent?.gdpr_consent_language}
              />
              {form.touched.GDPRConsent && Boolean(form.errors.GDPRConsent) ? (
                <span className="errorText">{form.errors.GDPRConsent}</span>
              ) : null}
            </FormGroup>
          ) : null}

          <Grid alignItems="baseline" container justifyContent="space-between">
            <div className="smallText">
              {formContent.privacy_policy_cta?.[0]?.copy
                ? parse(formContent.privacy_policy_cta?.[0]?.copy)
                : null}
            </div>

            <Button
              disabled={isLoading || hasPendingChanges}
              isLoading={form.isSubmitting}
              type="submit"
              variant="secondary"
            >
              {formContent.submit_text}
            </Button>
          </Grid>
        </form>
      ) : (
        <Typography className="formSubhead" component="p" variant="h3">
          Download file not found.
        </Typography>
      )}
    </FormWrapper>
  )
}

export default DownloadResourceForm
