import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

export const HeroSection = styled.section`
  padding: 96px 0 0;

  .headline {
    color: ${rspTheme.palette.primary.main};
    max-width: 28ch;
    margin-bottom: 32px;
  }

  .tag {
    margin-bottom: 16px;
    color: ${rspTheme.palette.text.hint};
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 1.5;

    > span + span::before {
      content: '|';
      margin-inline: 1rem;
    }
  }

  .heroImage {
    margin-bottom: 24px;
    border-radius: 6px;
    overflow: hidden;
    max-height: 480px;
  }

  .socialLinks {
    width: auto;
    > * + * {
      margin-inline-start: 2rem;
    }
  }

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    padding: 40px 0 8px;

    .headline {
      font-size: 2.8rem;
    }

    .metaContainer {
      justify-content: center;
      text-align: center;

      .metaInfo {
        width: 100%;
      }
    }
  }
`
